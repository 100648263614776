import {Provider} from "react-redux";
import store from "../misc/redux/store";
import ThemeProviderWrapper from "./ThemeProviderWrapper";

function App() {
    return (
        <Provider store={store}>
            <ThemeProviderWrapper/>
        </Provider>
    );
}

export default App;

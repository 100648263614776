type Citation = {
    country?: string,
    id?: string,
    journal_abbrev?: string,
    journal_id_astm?: string,
    journal_id_csd?: string,
    journal_id_issn?: string,
    journal_volume?: string,
    page_first?: string,
    page_last?: string,
    pdbx_database_id_pub_med?: string,
    rcsb_authors?: string[],
    rcsb_journal_abbrev?: string,
    title?: string,
    year?: number
}

export default class ProteinPostResponse {
    name: string
    id: string
    organisms: string[]
    citation: Citation
    classification: string
    image_path: string
    pdb_link: string

    constructor(name: string, organisms: string[], citation: Citation, classification: string, image_path: string, pdb_link: string, id: string) {
        this.name = name
        this.organisms = organisms
        this.citation = citation
        this.classification = classification
        this.image_path = image_path
        this.pdb_link = pdb_link
        this.id = id
    }

    static fromObject(object: ProteinPostResponse): ProteinPostResponse {
        return new ProteinPostResponse(
            object.name,
            object.organisms,
            object.citation,
            object.classification,
            object.image_path,
            object.pdb_link,
            object.id
        )
    }

    public toUrlParams() {
        return new URLSearchParams(
            {
                name: this.name,
                classification: this.classification,
                image_path: this.image_path,
                id: this.id,
                pdb_link: this.pdb_link,
                organisms: this.organisms.join(","),
                citation: this.citation.rcsb_authors?.join(",") +
                    (this.citation.year !== undefined ? `(${this.citation.year})` : "") +
                    (this.citation.title !== undefined ? " " + this.citation.title : "") +
                    (this.citation.page_first !== undefined ? this.citation.page_first : "") +
                    (this.citation.page_last !== undefined ? ` - ${this.citation.page_last}` : "")
            }
        ).toString()
    }
}
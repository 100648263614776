import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../../misc/redux/store";
import {JSMolRepository} from "../../data/repository/JSMolRepository";
import ResidueSelectionParser from "../../domain/proteinResidueSelection/ResidueSelectionParser";
import ProteinFileParser from "../../domain/proteinFileParser/ProteinFileParser";
import _ from "lodash";
import {CardMedia} from "@mui/material";

type JSmolContainerProps = {
    jsmolRepository: JSMolRepository,
    image_path: string | undefined
}

const JSMolContainer = React.memo(({jsmolRepository, image_path}: JSmolContainerProps) => {

    const proteinFile: string | undefined = useSelector((state: RootState) => state.proteinFile.value, {
        equalityFn: _.isEqual,
    })
    const selectionState = useSelector((state: RootState) => state.residueSelection, {
        equalityFn: _.isEqual,
    })
    const currentPDB = useSelector((state: RootState) => state.pdbCode.value, {
        equalityFn: _.isEqual,
    })
    const [generatedPDB, setGeneratedPDB] = useState<string | undefined>()


    useEffect(() => {
        if (proteinFile !== undefined && generatedPDB !== currentPDB && selectionState.proteinCode === currentPDB) {
            setGeneratedPDB(currentPDB)
            const script = new ResidueSelectionParser().parseToJsmolScript(selectionState, new ProteinFileParser(proteinFile).parse())
            // Uncomment this line to enable JSmol addon (be aware that it can break production mode)
            // jsmolRepository.load(proteinFile, script)
        }
    }, [currentPDB, generatedPDB, proteinFile, selectionState]);

    return (
        // Uncomment this line to enable JSmol addon (be aware that it can break production mode)
        // <div style={{height: "60vh", minHeight: 300, aspectRatio: 1, maxWidth: "85vw", zIndex: 0}}
        //      id={"jsmol-canvas-wrapper"}/>
        // comment this line to enable JSmol addon (be aware that it can break production mode)
        <CardMedia
            sx={{height: "60vh", minHeight: 300, aspectRatio: 1, maxWidth: "85vw", zIndex: 0}}
            image={image_path}
                title="Protein Image"
            />
    )
})

export default JSMolContainer
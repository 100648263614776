import {Divider, Typography, useTheme} from "@mui/material";

export default function Footer() {
    const theme = useTheme();

    return (
        <footer>
            <Divider/>
            <Typography variant={"caption"} style={{color: theme.palette.text.primary}}>
                The HPHOB application is provided by the Sano Centre for Computational Medicine (https://sano.science),
                and uses computational modules developed by Katarzyna Stąpor and Piotr Fabian on the basis of the FOD
                model authored by Irena Roterman-Konieczna and Leszek Konieczny. Please consult the indtroductory text
                at the top of the page for terms of use.
            </Typography>
        </footer>
    )
}
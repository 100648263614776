import {List, ListSubheader} from "@mui/material";
import Protein from "../../domain/proteinModels/Protein";
import ChainList from "./proteinElementsLists/ChainList";
import React, {useEffect, useState} from "react";
import PDBCode from "../../domain/PDBCode";
import {setProteinFile} from "../../../misc/redux/slices/ProteinFileSlices";
import {setPdbCode} from "../../../misc/redux/slices/PDBCodeSlices";
import ProteinFileParser from "../../domain/proteinFileParser/ProteinFileParser";
import {setProtein} from "../../../misc/redux/slices/ProteinSlices";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../misc/redux/store";
import deserializeSearchParams from "../../../misc/config/router/deserializeSearchParams";
import {ProteinSearchParams} from "../../domain/ProteinSearchParams";
import {proteinViewRoute} from "../../../misc/config/router/routes";
import {useSearchParams} from "react-router-dom";
import {ProteinRepository} from "../../data/repository/ProteinRepository";
import {initializeState} from "../../../misc/redux/slices/ResiduesSelectionSlices";
import {setComputationalState} from "../../../misc/redux/slices/ComputationalStateSlice";

type ProteinStructureProps = {
    proteinRepository: ProteinRepository,
    bioType: "desktop" | "mobile"
}

export default function ProteinStructures({proteinRepository, bioType}: ProteinStructureProps) {

    const proteinJSONString = useSelector((state: RootState) => state.protein.value)
    const [searchParams] = useSearchParams();
    const params = deserializeSearchParams<ProteinSearchParams>(proteinViewRoute.searchParams!, searchParams)
    const dispatch = useDispatch()
    const pdbCode = useSelector((state: RootState) => state.pdbCode.value)
    const selectionState = useSelector((state: RootState) => state.residueSelection.value)
    const [fetchingProgress, setFetchingProgress] = useState<number>(0)

    useEffect(() => {
        if ((proteinJSONString === undefined && params.id !== undefined) || (pdbCode !== params.id && params.id !== undefined)) {
            dispatch(setComputationalState({value: "not started"}))
            proteinRepository
                .fetchFile(new PDBCode(params.id), (event) => {
                    setFetchingProgress(event.progress === undefined ? 0 : Math.round(event.progress * 100))
                })
                .then((data) => data.text())
                .then((text) => {
                    dispatch(setProteinFile({value: text}))
                    dispatch(setPdbCode({value: params.id}))
                    return new ProteinFileParser(text).parse()
                })
                .then((parsedProtein) => {
                    dispatch(initializeState([parsedProtein, new PDBCode(params.id!).code]))
                    dispatch(setProtein({value: JSON.stringify(parsedProtein)}))
                })

        }
    }, [proteinJSONString, dispatch, proteinRepository, params, pdbCode]);

    return (
        <div style={{
            height: bioType === "desktop" ? "70vh" : "auto",
            maxHeight: bioType === "desktop" ? "none" : "70vh",

            overflowX: "hidden",
            marginBottom: 0,
            display: "flex",
            justifyContent: "center"
        }}>
            <div style={{marginTop: 10, width: "95%"}}>
                <List
                    sx={{width: '100%', minWidth: 350}}
                    subheader={
                        <ListSubheader>
                            {proteinJSONString === undefined ? `Fetching Protein: ${fetchingProgress}` : "Chains"}
                        </ListSubheader>
                    }
                >
                    {proteinJSONString === undefined ?
                        null :
                        (Protein.fromObject(JSON.parse(proteinJSONString))).chains.map((chain, index) =>
                            <ChainList
                                chain={chain}
                                index={index}
                                chainSelectionState={selectionState[index]}
                                key={chain.name + "chain-outer"}/>)
                    }
                </List>
            </div>

        </div>
    )
}
import PDBCode from "../../domain/PDBCode";
import {ProteinDataSource} from "../source/ProteinDataSource";
import ProteinPostResponse from "../../domain/APIResponses/ProteinPostResponse";
import {Blob} from "buffer";
import {AxiosProgressEvent} from "axios";

export interface ProteinRepository {
    fetchMetadata(code: PDBCode): Promise<ProteinPostResponse>

    fetchFile(code: PDBCode, updateProgress: (event: AxiosProgressEvent) => void): Promise<Blob>
}

export class DefaultProteinRepository implements ProteinRepository {
    dataSource: ProteinDataSource

    constructor(dataSource: ProteinDataSource) {
        this.dataSource = dataSource
    }

    async fetchMetadata(code: PDBCode): Promise<ProteinPostResponse> {
        return this.dataSource.fetchMetadata(code);
    }

    async fetchFile(code: PDBCode, updateProgress: (event: AxiosProgressEvent) => void): Promise<Blob> {
        return this.dataSource.fetchFile(code, updateProgress)
    }

}
import Chain from "../proteinModels/Chain";
import Structure from "../proteinModels/Structure";
import Residue from "../proteinModels/Residue";

export default class JsmolManipulator {
    jsmolRangeShow: (firstResidue: Residue, lastResidue: Residue, chainName: string) => void = (firstResidue, lastResidue, chainName) => {
        this.runJsmolScript(`display add ${firstResidue.id}-${lastResidue.id}:${chainName}; hide remove ${firstResidue.id}-${lastResidue.id}:${chainName};`)
    }

    jsmolRangeHide: (firstResidue: Residue, lastResidue: Residue, chainName: string) => void = (firstResidue, lastResidue, chainName) => {
        this.runJsmolScript(`display remove ${firstResidue.id}-${lastResidue.id}:${chainName}; hide add ${firstResidue.id}-${lastResidue.id}:${chainName};`)
    }

    jsmolHideAll: () => void = () => {
        this.runJsmolScript(`display remove *; hide add *;`)
    }

    jsmolShowChain: (chain: Chain) => void = (chain) => {
        const firstResidue = chain.structures[0].residues[0]
        const lastResidue = chain.structures[chain.structures.length - 1].residues[chain.structures[chain.structures.length - 1].residues.length - 1]
        this.runJsmolScript(`display add ${firstResidue.id}-${lastResidue.id}:${chain.name}; hide remove ${firstResidue.id}-${lastResidue.id}:${chain.name};`)
    }

    jsmolHideChain: (chain: Chain) => void = (chain) => {
        const firstResidue = chain.structures[0].residues[0]
        const lastResidue = chain.structures[chain.structures.length - 1].residues[chain.structures[chain.structures.length - 1].residues.length - 1]
        this.runJsmolScript(`display remove ${firstResidue.id}-${lastResidue.id}:${chain.name}; hide add ${firstResidue.id}-${lastResidue.id}:${chain.name};`)
    }

    jsmolHideStructure: (structure: Structure, chainName: string) => void = (structure, chainName) => {
        const firstResidue = structure.residues[0]
        const lastResidue = structure.residues[structure.residues.length - 1]
        this.runJsmolScript(`display remove ${firstResidue.id}-${lastResidue.id}:${chainName}; hide add ${firstResidue.id}-${lastResidue.id}:${chainName};`)
    }

    jsmolShowStructure: (structure: Structure, chainName: string) => void = (structure, chainName) => {
        const firstResidue = structure.residues[0]
        const lastResidue = structure.residues[structure.residues.length - 1]
        this.runJsmolScript(`display add ${firstResidue.id}-${lastResidue.id}:${chainName}; hide remove ${firstResidue.id}-${lastResidue.id}:${chainName};`)
    }

    private runJsmolScript(script: string) {
        try {
            // @ts-ignore
            window.Jmol.script(window.myJmol, script)
        } catch (e: any) {
            console.warn("JSmol addon is disabled")
        }
    }
}
import {
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Collapse,
    IconButton,
    IconButtonProps,
    styled,
    Typography
} from "@mui/material";
import {useSearchParams} from "react-router-dom";
import {proteinViewRoute} from "../../../misc/config/router/routes";
import deserializeSearchParams from "../../../misc/config/router/deserializeSearchParams";
import React, {useEffect} from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {ProteinSearchParams} from "../../domain/ProteinSearchParams";
import PDBCode from "../../domain/PDBCode";
import {setProteinFile} from "../../../misc/redux/slices/ProteinFileSlices";
import {setPdbCode} from "../../../misc/redux/slices/PDBCodeSlices";
import ProteinFileParser from "../../domain/proteinFileParser/ProteinFileParser";
import {initializeState} from "../../../misc/redux/slices/ResiduesSelectionSlices";
import {setProtein} from "../../../misc/redux/slices/ProteinSlices";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../misc/redux/store";
import {ProteinRepository} from "../../data/repository/ProteinRepository";
import {setComputationalState} from "../../../misc/redux/slices/ComputationalStateSlice";


interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}

type ProteinViewProps = {
    proteinRepository: ProteinRepository
}

export default function ProteinView({proteinRepository}: ProteinViewProps) {
    const [searchParams] = useSearchParams();
    const params = deserializeSearchParams<ProteinSearchParams>(proteinViewRoute.searchParams!, searchParams)
    const [expanded, setExpanded] = React.useState(false);
    const proteinJSONString = useSelector((state: RootState) => state.protein.value)
    const dispatch = useDispatch()
    const pdbCode = useSelector((state: RootState) => state.pdbCode.value)


    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const ExpandMore = styled((props: ExpandMoreProps) => {
        const {expand, ...other} = props;
        return <IconButton {...other} />;
    })(({theme, expand}) => ({
        transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    }));

    useEffect(() => {
        if ((proteinJSONString === undefined && params.id !== undefined) || (pdbCode !== params.id && params.id !== undefined)) {
            dispatch(setComputationalState({value: "not started"}))
            proteinRepository
                .fetchFile(new PDBCode(params.id), (event) => {
                })
                .then((data) => data.text())
                .then((text) => {
                    dispatch(setProteinFile({value: text}))
                    dispatch(setPdbCode({value: params.id}))
                    return new ProteinFileParser(text).parse()
                })
                .then((parsedProtein) => {
                    dispatch(initializeState([parsedProtein, new PDBCode(params.id!).code]))
                    dispatch(setProtein({value: JSON.stringify(parsedProtein)}))
                })

        }
    }, [proteinJSONString, dispatch, proteinRepository, params, pdbCode]);

    return (
        <div style={{minWidth: "100%", flex: 1, display: "flex", flexDirection: "column", justifyContent: "center"}}>
            <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 60,
                marginBottom: 60
            }}>
                <Card sx={{maxWidth: "70vw"}}>
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <CardMedia
                            sx={{minWidth: 100, aspectRatio: 1, height: "60vh", borderRadius: 10}}
                            image={params.image_path}
                            title="Protein Image"
                        />
                    </div>

                    <CardActions disableSpacing>
                        <Typography variant={"h6"}>{params.name}</Typography>
                        <ExpandMore
                            expand={expanded}
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="show more"
                            data-testid="expand-button"
                        >
                            <ExpandMoreIcon/>
                        </ExpandMore>
                    </CardActions>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <CardContent>
                            <Typography variant={"body2"}>{params.citation}</Typography>
                            <Typography variant={"body2"}>Classification: {params.classification}</Typography>
                            <Typography variant={"body2"}>Organisms: {params.organisms}</Typography>
                        </CardContent>
                    </Collapse>
                </Card>
            </div>
        </div>
    )

}
import Dashboard from "../../../app/presentation/dashboard/Dashboard";
import React from "react";
import ProteinView from "../../../app/presentation/proteinView/ProteinView";
import ProteinFragment from "../../../app/presentation/proteinFragment/ProteinFragment";
import {DefaultProteinRepository} from "../../../app/data/repository/ProteinRepository";
import {ProteinDataSource} from "../../../app/data/source/ProteinDataSource";
import {DefaultJSMolRepository} from "../../../app/data/repository/JSMolRepository";

export type Route = {
    path: string,
    element?: React.JSX.Element,
    routeElement?: React.JSX.Element,
    searchParams?: string[]
}

export const dashboardRoute: Route = {
    path: "/",
    element: <Dashboard/>,
}

export const proteinViewRoute: Route = {
    path: "/protein-view",
    element: <ProteinView proteinRepository={new DefaultProteinRepository(new ProteinDataSource())}/>,
    searchParams: ["name", "classification", "image_path", "pdb_link", "organisms", "citation", "id"]
}

export const computeRoute: Route = {
    path: "/compute",
    element: undefined
}

export const proteinFragmentRoute: Route = {
    path: "/protein-fragment-choose",
    element: <ProteinFragment jsmolRepository={new DefaultJSMolRepository()}
                              proteinRepository={new DefaultProteinRepository(new ProteinDataSource())}/>,
    searchParams: ["name", "classification", "image_path", "pdb_link", "organisms", "citation", "id"]
}

export const txtDownloadRoute: Route = {
    path: `${process.env.REACT_APP_BACKEND_COMPUTE_URL}/compute/download-txt`
}

export const csvDownloadRoute: Route = {
    path: `${process.env.REACT_APP_BACKEND_COMPUTE_URL}/compute/download-csv`
}


export default class ProteinErrorMessageCreator {
    status: number
    code: string | undefined
    message?: string

    constructor(code: string | undefined, status: number, message: string | undefined = undefined) {
        this.code = code
        this.message = message
        this.status = status
    }

    getErrorMessage(): string {
        switch (true) {
            case (this.status >= 500):
                return "Server failure"
            default:
                switch (this.code) {
                    case "ERR_NETWORK":
                        return "Cannot connect to server"
                    case "ERR_BAD_REQUEST":
                        return this.message !== null ? this.message! : "Unknown error"
                    default:
                        return "Unknown error"
                }
        }

    }
}
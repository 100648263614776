import {createSlice} from "@reduxjs/toolkit";

// Define a type for the slice state
interface ThemeModeState {
    value: 'dark' | 'light'
}

// Define the initial state using that type
const initialState: ThemeModeState = {
    value: 'light'
}

export const themeSlice = createSlice({
    name: 'theme',
    initialState: initialState,
    reducers: {
        changeToDark: (state) => {
            state.value = 'dark'
        },
        changeToLight: (state) => {
            state.value = 'light'
        },
    },
})

// Action creators are generated for each case reducer function
export const {changeToLight, changeToDark} = themeSlice.actions

export default themeSlice.reducer
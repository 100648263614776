import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface ProteinFileState {
    value?: string
}

const initialState: ProteinFileState = {
    value: undefined
}

export const proteinFileSlices = createSlice({
    name: 'proteinFile',
    initialState: initialState,
    reducers: {
        setProteinFile: (state, action: PayloadAction<ProteinFileState>) => {
            state.value = action.payload.value
        },
    },
})

// Action creators are generated for each case reducer function
export const {setProteinFile} = proteinFileSlices.actions

export default proteinFileSlices.reducer
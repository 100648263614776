import {Typography, useTheme} from "@mui/material";
import DashboardDescription from "./DashboardDescription";
import DashboardInput from "./DashboardInput";
import {DefaultProteinRepository, ProteinRepository} from "../../data/repository/ProteinRepository";
import {ProteinDataSource} from "../../data/source/ProteinDataSource";

function Dashboard() {
    const theme = useTheme();
    const proteinRepository: ProteinRepository = new DefaultProteinRepository(new ProteinDataSource())

    return (
        <div style={{paddingTop: 20}}>
            <Typography variant={"h3"} align={"center"} style={{color: theme.palette.text.primary}}>HPHOB</Typography>
            <Typography variant={"h5"} mt={1} align={"center"} style={{color: theme.palette.text.primary}}>
                An online tool to compute FOD model parameters for selected protein structures
            </Typography>
            <Typography variant={"subtitle2"} align={"center"} style={{color: theme.palette.text.primary}}>
                Based on a computational module developed by Irena Roterman and Leszek
                Konieczny, and implemented by Katarzyna Stąpor and Piotr Fabian.
            </Typography>
            <div style={{display: "flex", justifyContent: "center", marginTop: 25}}>
                <div style={{width: "80%"}}>
                    <DashboardDescription/>
                    <DashboardInput proteinRepository={proteinRepository}/>
                </div>
            </div>
        </div>
    )
}

export default Dashboard;
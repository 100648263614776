import {Collapse, List, ListItem, ListItemButton, ListItemText, ListSubheader} from "@mui/material";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import React, {useState} from "react";
import Chain from "../../../domain/proteinModels/Chain";
import StructureList from "./StructureList";
import {useDispatch} from "react-redux";
import {setChainState} from "../../../../misc/redux/slices/ResiduesSelectionSlices";
import JsmolManipulator from "../../../domain/jsmolManipulator/JsmolManipulator";

type ChainListProps = {
    chain: Chain,
    index: number,
    chainSelectionState: {
        structures: {
            [structureIndex: number]: {
                residues: { [residueName: string]: boolean },
                excludedResidues: number
            },
        },
        excludedResidues: number
    }
}

const ChainList = React.memo(({chain, index, chainSelectionState}: ChainListProps) => {
    const [expand, setExpand] = useState<boolean>(false)
    const dispatch = useDispatch()
    const jsmolManipulator = new JsmolManipulator()

    const handleOnClick = (e: React.MouseEvent) => {
        if (chainSelectionState.excludedResidues === 0) {
            jsmolManipulator.jsmolHideChain(chain)
        } else {
            jsmolManipulator.jsmolShowChain(chain)
        }
        dispatch(setChainState({chainIndex: index, newState: chainSelectionState.excludedResidues === 0}))
    }

    return (
        <>
            <ListItem sx={{
                display: "flex",
                borderStyle: chainSelectionState.excludedResidues === 0 ? null : 'solid',
                borderWidth: chainSelectionState.excludedResidues === 0 ? null : '0.6px',
                borderColor: chainSelectionState.excludedResidues === 0 ? null : 'red',
                width: 'fit-content',
                minWidth: 300,
                marginBottom: 1,
                borderRadius: 2,
            }} key={chain.name + "chain"}>
                <ListItemText
                    sx={{
                        flexGrow: 5,
                        "&:hover": {
                            cursor: "pointer"
                        }
                    }}
                    onClick={handleOnClick}
                >{chain.name}</ListItemText>
                <ListItemButton sx={{flexGrow: 1}} onClick={() => setExpand(!expand)}>
                    {expand ? <ExpandLess/> : <ExpandMore/>}
                </ListItemButton>
            </ListItem>
            <Collapse key={chain.name + "collapse"} in={expand} timeout="auto" unmountOnExit>
                <List disablePadding subheader={<ListSubheader sx={{marginLeft: 2}}>Structures</ListSubheader>}>
                    {chain.structures.map((structure, structureIndex) =>
                        <StructureList
                            chainName={chain.name}
                            structureSelectionState={chainSelectionState.structures[structureIndex]}
                            chainIndex={index}
                            structureIndex={structureIndex}
                            structure={structure}
                            key={chain.name + structure.type + structureIndex}
                        />
                    )}
                </List>
            </Collapse>
        </>
    )
})

export default ChainList;
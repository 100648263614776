import {ResidueSelectionState} from "../ResidueSelectionState";
import Protein from "../proteinModels/Protein";

export default class ResidueSelectionParser {
    parseToCompute: (selection: ResidueSelectionState, protein: Protein) => {
        [firstAtom: string]: string
    } = (selection, protein) => {
        const result: { [firstAtom: string]: string } = {}
        Object.entries(selection.value).forEach(([chainIndex, chain]) => {
            Object.values(chain.structures).forEach((structure) => {
                Object.entries(structure.residues).forEach(([residueName, excluded]) => {
                    if (excluded) {
                        const residue = protein.chains[parseInt(chainIndex, 10)].residuesHash[residueName]
                        result[residue.atoms[0].toString(10)] = residue.atoms[residue.atoms.length - 1].toString(10)
                    }
                })
            })
        })
        return result
    }

    parseToJsmolScript: (selection: ResidueSelectionState, protein: Protein) => string = (selection, protein) => {
        let displayRemove: string = "display remove "
        let hideAdd: string = "hide add "
        Object.entries(selection.value).forEach(([chainIndex, chain]) => {
            Object.values(chain.structures).forEach((structure) => {
                Object.entries(structure.residues).forEach(([residueName, excluded]) => {
                    if (excluded) {
                        const residue = protein.chains[parseInt(chainIndex, 10)].residuesHash[residueName]
                        displayRemove += `${residue.id}:${protein.chains[parseInt(chainIndex, 10)].name},`
                        hideAdd += `${residue.id}:${protein.chains[parseInt(chainIndex, 10)].name},`
                    }
                })
            })
        })
        if (displayRemove[displayRemove.length - 1] === ",") {
            displayRemove = displayRemove.slice(0, -1)
            hideAdd = hideAdd.slice(0, -1)
        }
        if (displayRemove === "display remove ") {
            return " display all;"
        }
        return displayRemove + "; " + hideAdd + ";"
    }
}
import axios from "axios";
import PDBCode from "../../domain/PDBCode";

export interface ComputationalRepository {
    startComputation: (toExclude: { [firstAtom: string]: string }, code: PDBCode) => Promise<string>
}

export default class ComputationalRepositoryImpl implements ComputationalRepository {

    changeComputationalState: (state: "not started" | "in progress" | "finished" | "error") => void

    constructor(changeComputationalState: (state: "not started" | "in progress" | "finished" | "error") => void) {
        this.changeComputationalState = changeComputationalState
    }

    startComputation: (toExclude: {
        [firstAtom: string]: string
    }, code: PDBCode) => Promise<string> = async (toExclude, code) => {
        this.changeComputationalState("in progress")
        try {
            const result = await axios.post(
                `${process.env.REACT_APP_BACKEND_COMPUTE_URL}/compute`,
                {pdb_code: code.code, structures_to_skip: toExclude},
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }
            )
            this.changeComputationalState("finished")
            return result.data
        } catch (e: any) {
            this.changeComputationalState("error")
            throw e
        }
    }
}
import PDBCode from "../../domain/PDBCode";
import axios, {AxiosProgressEvent} from "axios";
import ProteinPostResponse from "../../domain/APIResponses/ProteinPostResponse";
import Protein from "../../domain/proteinModels/Protein";
import {Blob} from "buffer"

export class ProteinDataSource {
    async fetchMetadata(code: PDBCode): Promise<ProteinPostResponse> {
        return await axios.post(
            `${process.env.REACT_APP_BACKEND_API_URL}/protein`,
            {pdb_code: code.code.toLowerCase()},
            {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
        )
            .then((response) => response.data)
            .then((data) => ProteinPostResponse.fromObject(data));
    }

    async fetchFile(code: PDBCode, updateProgress: (event: AxiosProgressEvent) => void = (e) => {
    }): Promise<Blob> {
        return await axios.get(Protein.proteinURL(code),
            {
                responseType: 'blob',
                onDownloadProgress: (progressEvent) => updateProgress(progressEvent)
            }
        )
            .then((response) => response.data as Blob)
    }
}
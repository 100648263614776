import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {Article} from "@mui/icons-material";

function DashboardDescription() {
    return (
        <div>
            <Accordion sx={{boxShadow: 2}}>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                    <Typography>How to use?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant={"body2"} mt={2} align={"left"}>The HPHOB application computes the degree to
                        which the given protein
                        molecule complies with the micelle-like structural pattern, mathematically modeled as a 3D
                        Gaussian distribution of hydrophobicity. In this model, participation of the aqueous solvent
                        directs hydrophobic residues towards the central part of the protein body, while exposure of
                        polar residues on the surface makes the protein soluble. Local deviations from the model - such
                        as exposure of hydrophobicity on the surface - prime the protein for interaction with selected
                        molecules (ligands or substrates), which is why actual proteins do not follow the micelle-like
                        structure with perfect accuracy.</Typography>

                    <Typography variant={"body2"} mt={2} align={"left"}>The Relative Distance (RD) parameter
                        mathematically
                        expresses the difference between the actual (observed) structure of the target protein, and
                        its corresponding mathematical model. Values below 0.5 indicate the presence of a well-defined
                        hydrophobic core, overlaid by a hydrophilic shell, while higher values suggest that no such
                        core is present.</Typography>

                    <Typography variant={"body2"} mt={2} align={"left"}>To calculate RD values for the target protein,
                        please begin
                        by entering its Protein DataBank (PDB) identifier. You will then be prompted to select
                        specific secondary folds or residue ranges (up to the entire PDB structure)
                        for analysis.</Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{boxShadow: 2}}>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                    <Typography>Terms of use</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant={"body2"} align={"left"}>If you publish results obtained at least partially by
                        using this
                        service, please include the following citations in your publication:</Typography>
                    <List>
                        <ListItem style={{marginTop: 10}}>
                            <ListItemIcon> <Article/> </ListItemIcon>
                            <ListItemText>
                                <Typography variant={"body2"} align={"left"}>
                                    Kalinowska B, Banach M, Konieczny L, Roterman I, "Application of Divergence Entropy
                                    to Characterize the Structure of the Hydrophobic Core in DNA Interacting Proteins";
                                    Entropy
                                    2015, 17(3), 1477-1507; https://doi.org/10.3390/e17031477
                                </Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem style={{marginTop: 10}}>
                            <ListItemIcon> <Article/> </ListItemIcon>
                            <ListItemText>
                                <Typography variant={"body2"} align={"left"}>
                                    Roterman-Konieczna I, "From globular proteins to amyloids”; Amsterdam, The
                                    Netherlands;
                                    Oxford, UK; Cambridge MA. USA; Elsevier 2020
                                </Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem style={{marginTop: 10}}>
                            <ListItemIcon> <Article/> </ListItemIcon>
                            <ListItemText>
                                <Typography variant={"body2"} align={"left"}>
                                    Konieczny L, Roterman I, "Description of the fuzzy oil drop model", in:
                                    Roterman-Konieczna I,
                                    ed., From Globular Proteins to Amyloids, pp. 1-12, Elsevier 2020, Amsterdam,
                                    The Netherlands; Oxford, UK; Cambridge MA, USA; Electronic version available :
                                    https://www.sciencedirect.com/book/9780081029817/from-globular-proteins-to-amyloids
                                </Typography>
                            </ListItemText>
                        </ListItem>
                    </List>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export default DashboardDescription;
export enum ProteinFileRecord {
    ATOM = "ATOM",
    HETATM = "HETATM",
    HELIX = "HELIX",
    SHEET = "SHEET",
    SSBOND = "SSBOND",
    OTHER = "OTHER",
    TER = "TER",
    ANISOU = "ANISOU",
    MODEL = "MODEL",
    ENDMDL = "ENDMDL"
}
import ModeSlider from "./presentation/ModeSlider/ModeSlider";
import {
    Button,
    createTheme,
    CssBaseline,
    darkScrollbar,
    PaletteMode,
    Snackbar,
    SnackbarContent,
    ThemeOptions,
    ThemeProvider
} from "@mui/material";
import {useSelector} from "react-redux";
import {RootState} from "../misc/redux/store";
import Footer from "./presentation/footer/Footer";
import {Outlet} from "react-router-dom";
import React, {useEffect, useState} from "react";
import AppBarWrapper from "./presentation/AppBar/AppBarWrapper";
import {grey} from "@mui/material/colors";

function ThemeProviderWrapper() {
    const themeMode = useSelector((state: RootState) => state.theme.value)
    const vertical = "bottom"
    const horizontal = "center"
    const computationalState = useSelector((state: RootState) => state.computationalState.value)
    const computationalResult = useSelector((state: RootState) => state.computationalResult.value)

    const action = <Button color="inherit" variant="outlined" size="small" onClick={() => setShow(false)}>Hide</Button>
    const [show, setShow] = useState(true)

    useEffect(() => {
        setShow(true)
    }, [computationalState]);

    const getDesignTokens: (mode: PaletteMode) => ThemeOptions = (mode: PaletteMode) => ({
        palette: {
            mode: themeMode
        },
        components: {
            MuiCssBaseline: {
                styleOverrides: {
                    html: {
                        ...darkScrollbar(
                            mode === "light"
                                ? {
                                    track: grey[200],
                                    thumb: grey[400],
                                    active: grey[400]
                                }
                                : undefined
                        ),
                        //scrollbarWidth for Firefox
                        scrollbarWidth: "thin"
                    }
                }
            }
        }
    });
    const mode: PaletteMode = themeMode === "dark" ? "dark" : "light"
    const theme = createTheme(getDesignTokens(mode));


    return (
        <ThemeProvider theme={theme}>
            <div style={
                {
                    backgroundColor: theme.palette.background.default,
                    minHeight: "100vh",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "stretch",
                    alignContent: "stretch"
                }
            }>
                <CssBaseline/>
                <ModeSlider/>
                <AppBarWrapper/>
                <Outlet/>
                <Footer/>
                <Snackbar
                    open={(computationalState === "finished" || computationalState === "error") && show}
                    anchorOrigin={{vertical, horizontal}}
                    onClose={() => setShow(false)}
                    autoHideDuration={computationalState === "finished" ? 3600000 : 3000}
                    color={computationalState === "finished" ? theme.palette.success.main.toString() : theme.palette.error.main.toString()}
                    message="I love snacks"
                >
                    <SnackbarContent
                        style={{backgroundColor: computationalState === "finished" ? theme.palette.success.main.toString() : theme.palette.error.main.toString()}}
                        message={computationalResult.split("\n").map((line) =>
                            <div key={"result+span" + line} id="client-snackbar">{line}</div>)}
                        action={computationalState === "finished" ? action : null}
                    />
                </Snackbar>
            </div>
        </ThemeProvider>
    )
}

export default ThemeProviderWrapper;
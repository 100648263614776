import Protein from "../proteinModels/Protein";
import Chain from "../proteinModels/Chain";
import Structure from "../proteinModels/Structure";
import Residue from "../proteinModels/Residue";
import LineInterpreter from "./LineInterpreter";
import ProteinBuilder from "./ProteinBuilder";
import OtherResiduesFinder from "./OtherResiduesFinder";

export default class ProteinFileParser {
    file: string
    proteinBuilder: ProteinBuilder = new ProteinBuilder()
    otherResiduesFinder: OtherResiduesFinder = new OtherResiduesFinder()

    constructor(file: string) {
        this.file = file
    }

    public parse(): Protein {
        return this.generateProtein(LineInterpreter.toLines(this.file))
    }

    private generateProtein: (lines: string[]) => Protein = (lines) => {
        const residues: {
            [chainName: string]: { [number: string]: Residue }
        } = this.proteinBuilder.createResidues(lines)
        const structures: { [chainName: string]: Structure[] } = this.proteinBuilder.createStructures(lines, residues)
        const otherStructures: {
            [chainName: string]: Structure[]
        } = this.otherResiduesFinder.getOtherStructures(LineInterpreter.toLines(this.file), structures, residues)
        Object.entries(structures).forEach((entry) => structures[entry[0]].push(...otherStructures[entry[0]]))
        Object.keys(otherStructures).forEach((chainName) => {
            if (structures[chainName] === undefined) structures[chainName] = otherStructures[chainName]
        })
        return new Protein(Object.entries(structures).map((structure) =>
            new Chain(structure[1].toSorted((a, b) => parseInt(a.firstResidue) - parseInt(b.firstResidue)), structure[0])
        ))
    }
}
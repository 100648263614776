import {Box, Card,} from "@mui/material";
import React, {useEffect, useState} from "react";
import {ProteinRepository} from "../../data/repository/ProteinRepository";
import {JSMolRepository} from "../../data/repository/JSMolRepository";
import JSMolContainer from "./JSMolContainer";
import ProteinStructures from "./ProteinStructures";
import ResiduesRangeSelect from "./ResiduesRangeSelect";
import {useSearchParams} from "react-router-dom";
import deserializeSearchParams from "../../../misc/config/router/deserializeSearchParams";
import {ProteinSearchParams} from "../../domain/ProteinSearchParams";
import {proteinViewRoute} from "../../../misc/config/router/routes";

const getBioType = (matches: boolean) => matches ? 'desktop' : 'mobile';
const MIN_WIDTH = 1000;

type ProteinFragmentProps = {
    proteinRepository: ProteinRepository,
    jsmolRepository: JSMolRepository
}

export default function ProteinFragment({proteinRepository, jsmolRepository}: ProteinFragmentProps) {
    const [bioType, setBioType] = useState<"desktop" | "mobile">(() => getBioType(window.innerWidth > MIN_WIDTH));
    const [searchParams] = useSearchParams();
    const params = deserializeSearchParams<ProteinSearchParams>(proteinViewRoute.searchParams!, searchParams)

    useEffect(() => {
        const mql = window.matchMedia(`(min-width: ${MIN_WIDTH}px)`);
        const handler = (e: { matches: boolean; }) => setBioType(getBioType(e.matches));
        mql.addEventListener('change', handler);
        return () => {
            mql.removeEventListener('change', handler);
        };
    }, []);



    return (
        <div style={{display: "flex", justifyContent: "center"}}>
            <div style={{
                marginTop: 60,
                marginBottom: 60,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}>
                <Card sx={{maxWidth: "90vw"}}>
                    <Box sx={{display: "flex", flexDirection: bioType === "desktop" ? "row" : "column"}}>
                        <div>
                            <JSMolContainer jsmolRepository={jsmolRepository} image_path={params.image_path}/>
                            <ResiduesRangeSelect/>
                        </div>
                        <ProteinStructures proteinRepository={proteinRepository} bioType={bioType}/>
                    </Box>
                </Card>
            </div>

        </div>
    )
}
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface ProteinState {
    value?: string
}

const initialState: ProteinState = {
    value: undefined
}

export const proteinSlices = createSlice({
    name: 'protein',
    initialState: initialState,
    reducers: {
        setProtein: (state, action: PayloadAction<ProteinState>) => {
            state.value = action.payload.value
        },
    },
})

// Action creators are generated for each case reducer function
export const {setProtein} = proteinSlices.actions

export default proteinSlices.reducer
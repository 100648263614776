import {ListItem, ListItemText} from "@mui/material";
import React from "react";
import Residue from "../../../domain/proteinModels/Residue";
import {useDispatch} from "react-redux";
import {setResidueState} from "../../../../misc/redux/slices/ResiduesSelectionSlices";
import JsmolManipulator from "../../../domain/jsmolManipulator/JsmolManipulator";

type ResidueListProps = {
    residue: Residue,
    chainIndex: number,
    chainName: string,
    residueId: string,
    structureIndex: number,
    residueSelectionState: boolean
}

const ResidueList = React.memo(({
                                    residue,
                                    chainIndex,
                                    chainName,
                                    residueId,
                                    structureIndex,
                                    residueSelectionState
                                }: ResidueListProps) => {
    const dispatch = useDispatch()
    const jsmolManipulator = new JsmolManipulator()

    const handleOnClick: (e: React.MouseEvent) => void = (e) => {
        dispatch(setResidueState({chainIndex, structureIndex, residueId, newState: !residueSelectionState}))
        if (residueSelectionState) {
            jsmolManipulator.jsmolRangeShow(residue, residue, chainName)
        } else {
            jsmolManipulator.jsmolRangeHide(residue, residue, chainName)
        }
    }

    return (
        <ListItem sx={{
            marginLeft: 4,
            marginBottom: 1,
            borderRadius: 2,
            width: 'fit-content',
            minWidth: 200,
            borderStyle: residueSelectionState ? 'solid' : null,
            borderWidth: residueSelectionState ? '0.6px' : null,
            borderColor: residueSelectionState ? 'red' : null,
            "&:hover": {
                cursor: "pointer"
            }
        }}
                  onClick={handleOnClick}
        >
            <ListItemText>{residue.name},
                atoms: {residue.atoms[0] + " - " + residue.atoms[residue.atoms.length - 1]}</ListItemText>
        </ListItem>
    )
})

export default ResidueList;
export default class Residue {
    constructor(name: string, id: string, atoms: number[]) {
        this._atoms = atoms
        this._name = name
        this._id = id
    }

    private _id: string

    get id(): string {
        return this._id;
    }

    private _name: string

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    private _atoms: number[]

    get atoms(): number[] {
        return this._atoms;
    }

    set atoms(value: number[]) {
        this._atoms = value;
    }

    set id(value: string) {
        this._id = value;
    }

    public static fromObject(object: any): Residue {
        return new Residue(object._name, object._id, object._atoms)
    }

}
import Structure from "./Structure";
import Residue from "./Residue";

export default class Chain {
    residuesHash: { [residueName: string]: Residue } = {}

    private _structures: Structure[]

    get structures(): Structure[] {
        return this._structures;
    }
    name: string
    structureResidues: number[] = []
    residueAtoms: number[][] = []

    constructor(structures: Structure[], name: string) {
        this._structures = structures
        this.name = name
        this.structureResidues.push(0)
        this._structures.forEach((structure) =>
            this.structureResidues.push(this.structureResidues[this.structureResidues.length - 1] + structure.residues.length)
        )
        this._structures.forEach((structure, structureIndex) => {
            const res: number[] = [structureIndex === 0 ? 0 : this.residueAtoms[structureIndex - 1][this.residueAtoms[structureIndex - 1].length - 1]]
            structure.residues.forEach((residue, residueIndex) => {
                res.push(res[residueIndex] + residue.atoms.length)
                this.residuesHash[residue.id] = residue
            })
            this.residueAtoms.push(res)
        })
    }

    set structures(value: Structure[]) {
        this._structures = value;
    }

    get residues() {
        return this.residuesHash
    }

    public static fromObject(object: any): Chain {
        return new Chain(object._structures.map((structure: any) => Structure.fromObject(structure)), object.name)
    }
}
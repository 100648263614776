import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import {Switch, useTheme} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {changeToDark, changeToLight} from "../../../misc/redux/slices/ThemeSlice";
import {RootState} from "../../../misc/redux/store";

function ModeSlider() {
    const dispatch = useDispatch()
    const currentMode = useSelector((state: RootState) => state.theme.value)
    const theme = useTheme()

    return (
        <div style={{position: "fixed", top: 7, right: 20, zIndex: 10000}}>
            <div style={{display: "flex", alignItems: "center"}}>
                <LightModeIcon htmlColor={theme.palette.text.primary}/>
                <Switch
                    checked={currentMode === 'dark'}
                    onChange={(event, checked) => checked ?
                        dispatch(changeToDark()) :
                        dispatch(changeToLight())}
                />
                <DarkModeIcon htmlColor={theme.palette.text.primary}/>
            </div>

        </div>
    )
}

export default ModeSlider;
import Chain from "./Chain";
import PDBCode from "../PDBCode";

export default class Protein {
    chains: Chain[]

    constructor(chains: Chain[]) {
        this.chains = chains;
    }

    public static proteinURL(pdbCode: PDBCode): string {
        return `${process.env.REACT_APP_BACKEND_API_URL}/protein-file/${pdbCode.code.toLowerCase()}`
    }

    public static fromObject(object: any): Protein {
        return new Protein(object.chains.map((chain: any) => Chain.fromObject(chain)))
    }
}
import {Button, FormControl, LinearProgress, TextField, Typography, useTheme} from "@mui/material";
import PDBCode from "../../domain/PDBCode";
import React, {useState} from "react";
import {ProteinRepository} from "../../data/repository/ProteinRepository";
import {AxiosError} from "axios";
import ProteinErrorMessageCreator from "../../domain/errorMessageCreators/ProteinErrorMessageCreator";
import {useNavigate} from "react-router-dom";
import {proteinViewRoute} from "../../../misc/config/router/routes";

type DashboardInputProps = {
    proteinRepository: ProteinRepository
}

export default function DashboardInput({proteinRepository}: DashboardInputProps) {
    const [code, setCode] = useState<PDBCode>(new PDBCode(""))
    const [searching, setSearching] = useState(false)
    const [errors, setErrors] = useState<string[]>([])
    const theme = useTheme();
    const errorColor = theme.palette.mode === 'light' ? theme.palette.error.light : theme.palette.error.dark
    const navigate = useNavigate();

    async function handleSearchSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        setErrors([])
        if (code?.valid()) {
            setSearching(true)
            try {
                let protein = await proteinRepository.fetchMetadata(code)
                protein.id = code.code.toLowerCase()
                navigate(`${proteinViewRoute.path}/?${protein.toUrlParams()}`)
            } catch (error) {
                if (error instanceof AxiosError) {
                    setErrors([new ProteinErrorMessageCreator(error?.code, error.response!.status, error.response!.data?.message).getErrorMessage()])
                } else {
                    setErrors([new ProteinErrorMessageCreator("OTHER_CODE", 200).getErrorMessage()])
                }
            } finally {
                setSearching(false)
            }
        } else {
            if (code !== null)
                setErrors(code?.errors)
        }
    }

    return (
        <>
            <Typography variant={"body2"} style={{marginTop: 25, color: theme.palette.text.primary}}>
                Please supply a 4-character code of a protein. Pressing the Search button will look for that
                protein inside RCSB's Protein Data Bank (PDB). If the protein is found, it will be downloaded
                and
                presented for your inspection before further processing.</Typography>
            <div style={{display: "flex", justifyContent: "center", marginTop: 25, marginBottom: 25}}>
                <div style={{width: 300}}>
                    <form onSubmit={(e) => handleSearchSubmit(e)}>
                        <FormControl style={{width: "100%"}}>
                            <TextField
                                name={"dashboard-input-textinput"}
                                id={"dashboard-input-textinput"}
                                label="4-character code"
                                variant="outlined"
                                value={code?.code}
                                onChange={(e) => setCode(new PDBCode(e.target.value))}
                                error={errors.length !== 0}
                            />
                            {errors.length > 0 ?
                                <Typography variant={"caption"} color={errorColor}
                                            style={{display: "block"}}>{errors[0]}</Typography>
                                : null
                            }
                            <Button
                                style={{marginTop: 25}}
                                variant="outlined"
                                disabled={searching}
                                sx={{width: 100}}
                                data-testid={"dashboard-input-button"}
                                type="submit"
                            >Search</Button>
                            {searching ? <LinearProgress style={{marginTop: 25}}/> : null}
                        </FormControl>
                    </form>


                </div>
            </div>
        </>
    )
}
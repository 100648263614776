import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface ComputationalResult {
    value: string
}

const initialState: ComputationalResult = {
    value: ""
}

export const computationalResultSlice = createSlice({
    name: 'computationalResultSlice',
    initialState: initialState,
    reducers: {
        setResult: (state, action: PayloadAction<ComputationalResult>) => {
            state.value = action.payload.value
        },
    },
})


// Action creators are generated for each case reducer function
export const {setResult} = computationalResultSlice.actions

export default computationalResultSlice.reducer
import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Typography
} from "@mui/material";
import React, {ReactNode, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../misc/redux/store";
import Chain from "../../domain/proteinModels/Chain";
import Protein from "../../domain/proteinModels/Protein";
import {ResidueSelectionState} from "../../domain/ResidueSelectionState";
import {updateState} from "../../../misc/redux/slices/ResiduesSelectionSlices";
import ResidueRangeSelectorImpl, {
    ResidueRangeSelector
} from "../../domain/proteinResidueSelection/ResidueRangeSelector";
import JsmolManipulator from "../../domain/jsmolManipulator/JsmolManipulator";

const ResiduesRangeSelect = () => {
    const [lowerLimit, setLowerLimit] = useState<string>("")
    const [upperLimit, setUpperLimit] = useState<string>("")
    const [limitError, setLimitError] = useState<string>("")

    const proteinJSONString = useSelector((state: RootState) => state.protein.value)
    const chains: Chain[] = proteinJSONString !== undefined ? Protein.fromObject(JSON.parse(proteinJSONString)).chains : []
    const [selectedChain, setSelectedChain] = useState<number>(0)
    const dispatch = useDispatch()
    const residueSelection: ResidueSelectionState = useSelector((state: RootState) => state.residueSelection)
    const proteinJSON = useSelector((state: RootState) => state.protein.value)
    const protein = proteinJSON ? Protein.fromObject(JSON.parse(proteinJSON)) : undefined
    const residueRangeSelector: ResidueRangeSelector = new ResidueRangeSelectorImpl()
    const jsmolManipulator: JsmolManipulator = new JsmolManipulator()


    const onLowerLimitChange = (e: React.ChangeEvent<HTMLInputElement>) => setLowerLimit(e.currentTarget.value)
    const onUpperLimitChange = (e: React.ChangeEvent<HTMLInputElement>) => setUpperLimit(e.currentTarget.value)
    const onChainChange = (e: SelectChangeEvent, child: ReactNode) => setSelectedChain(parseInt(e.target.value, 10))

    const onExcludeClick = () => {
        try {
            setLimitError("")
            dispatch(updateState(residueRangeSelector.excludeInRange(lowerLimit, upperLimit, selectedChain, residueSelection)))
            if (protein !== undefined) {
                const firstResidue = protein.chains[selectedChain].residuesHash[lowerLimit]
                const lastResidue = protein.chains[selectedChain].residuesHash[upperLimit]
                jsmolManipulator.jsmolRangeHide(firstResidue, lastResidue, protein.chains[selectedChain].name)
            }

        } catch (e: any) {
            setLimitError(e)
        }

    }

    const onExcludeAllClick = () => {
        dispatch(updateState(residueRangeSelector.excludeAll(residueSelection)))
        jsmolManipulator.jsmolHideAll()
    }

    const onIncludeClick = () => {
        if (lowerLimit !== "" && upperLimit !== "") {
            try {
                setLimitError("")
                dispatch(updateState(residueRangeSelector.includeInRange(lowerLimit, upperLimit, selectedChain, residueSelection)))
                if (protein !== undefined) {
                    const firstResidue = protein.chains[selectedChain].residuesHash[lowerLimit]
                    const lastResidue = protein.chains[selectedChain].residuesHash[upperLimit]
                    jsmolManipulator.jsmolRangeShow(firstResidue, lastResidue, protein.chains[selectedChain].name)
                }
            } catch (e: any) {
                setLimitError(e)
            }
        }
    }

    return (
        <>
            <div style={{marginTop: 8, marginBottom: 20, display: "flex", minHeight: "fit-content"}}>
                <div style={{
                    flexGrow: 1,
                    width: 0,
                    display: "flex",
                    flexDirection: "column"
                }}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            minHeight: "fit-content"
                        }}
                    >
                        <TextField
                            type={"text"}
                            label={"From"}
                            value={lowerLimit}
                            onChange={onLowerLimitChange}
                            sx={{width: "30%", minWidth: 90}}
                            size={"small"}
                            error={limitError !== ""}
                        />
                        <TextField
                            type={"text"}
                            label={"To"}
                            value={upperLimit}
                            onChange={onUpperLimitChange}
                            sx={{width: "30%", minWidth: 90}}
                            size={"small"}
                            error={limitError !== ""}
                        />

                        <FormControl sx={{width: "20%", minWidth: 70}} size={"small"}>
                            <InputLabel>Chain</InputLabel>
                            <Select
                                value={selectedChain.toString(10)}
                                onChange={onChainChange}
                                label="Chain"
                                size={"small"}
                            >
                                {chains.map((chain, index) =>
                                    <MenuItem value={index.toString(10)}
                                              key={chain.name + "menu-item"}>{chain.name}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        <Typography variant="body1" fontSize={12} color={(theme) => theme.palette.error.main}>
                            {limitError}
                        </Typography>
                    </div>

                </div>

            </div>
            <div style={{display: "flex", justifyContent: "space-evenly", marginBottom: 8}}>
                <Button
                    sx={{width: "20%", minWidth: 75}}
                    onClick={onExcludeClick}
                    size={"small"}
                    variant="outlined"
                >Exclude</Button>
                <Button
                    sx={{width: "20%", minWidth: 120}}
                    size={"small"}
                    onClick={onExcludeAllClick}
                    variant="outlined"
                >Exclude All</Button>
                <Button
                    sx={{width: "20%", minWidth: 75}}
                    onClick={onIncludeClick}
                    size={"small"}
                    variant="outlined"
                >Include</Button>
            </div>
        </>
    )
}

export default ResiduesRangeSelect;
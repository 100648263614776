export interface JSMolRepository {
    load(pdbFile: string, startingScript: string): void
}

export class DefaultJSMolRepository implements JSMolRepository {

    load(pdbFile: string, startingScript: string) {
        const foundScript = document.querySelector('script[src="/JSmol.min.js"]')
        if (foundScript !== null) {
            foundScript?.remove()
        }
        const script = document.createElement('script');
        script.src = "/JSmol.min.js";
        script.onload = () => {
            this.enableJmol(pdbFile, startingScript)
        }
        document.head.appendChild(script);
    }


    private enableJmol: (pdbFile: string, startingScript: string) => void = (pdbFile, startingScript) => {
        const JmolInfo = {
            width: '100%',
            height: '100%',
            color: '#E2F4F5',
            j2sPath: '/j2s',
            use: 'html5',
            script: `load inline "${pdbFile}"; backbone only; backbone off; cartoon on; color monomer; ${startingScript}`
        }
        const containerElement = document.getElementById('jsmol-canvas-wrapper');
        if (containerElement) {
            // @ts-ignore
            containerElement.innerHTML = window.Jmol.getAppletHtml('myJmol', JmolInfo)
        }
    }
}
import {Collapse, List, ListItem, ListItemButton, ListItemText, ListSubheader} from "@mui/material";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import React, {useState} from "react";
import Structure from "../../../domain/proteinModels/Structure";
import ResidueList from "./ResidueList";
import {useDispatch} from "react-redux";
import {setStructureState} from "../../../../misc/redux/slices/ResiduesSelectionSlices";
import JsmolManipulator from "../../../domain/jsmolManipulator/JsmolManipulator";

type StructureListProps = {
    structureIndex: number,
    chainName: string,
    chainIndex: number,
    structure: Structure,
    structureSelectionState: {
        residues: { [residueName: string]: boolean },
        excludedResidues: number
    }
}

const StructureList = React.memo(({
                                      chainIndex,
                                      chainName,
                                      structureIndex,
                                      structure,
                                      structureSelectionState
                                  }: StructureListProps) => {
    const [expand, setExpand] = useState<boolean>(false)
    const dispatch = useDispatch()
    const jsmolManipulator = new JsmolManipulator()

    const handleOnClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        dispatch(setStructureState({
            chainIndex,
            structureIndex,
            newState: structureSelectionState.excludedResidues === 0
        }))
        if (structureSelectionState.excludedResidues === 0) {
            jsmolManipulator.jsmolHideStructure(structure, chainName)
        } else {
            jsmolManipulator.jsmolShowStructure(structure, chainName)
        }
    }

    return (
        <>
            <ListItem sx={{
                display: "flex",
                marginLeft: 2,
                borderStyle: structureSelectionState.excludedResidues === 0 ? null : 'solid',
                borderWidth: structureSelectionState.excludedResidues === 0 ? null : '0.6px',
                borderColor: structureSelectionState.excludedResidues === 0 ? null : 'red',
                width: 'fit-content',
                minWidth: 300,
                marginBottom: 1,
                borderRadius: 2,
            }}
            >
                <ListItemText
                    sx={{
                        flexGrow: 5,
                        "&:hover": {
                            cursor: "pointer"
                        }
                    }}
                    onClick={handleOnClick}
                >{structure.type},
                    residues: {structure.firstResidue + " - " + structure.lastResidue}</ListItemText>
                <ListItemButton sx={{flexGrow: 1}} onClick={() => setExpand(!expand)}>
                    {expand ? <ExpandLess/> : <ExpandMore/>}
                </ListItemButton>
            </ListItem>
            <Collapse sx={{marginLeft: 2}} in={expand}>
                <List disablePadding subheader={<ListSubheader sx={{marginLeft: 4}}>Residues</ListSubheader>}>
                    {structure.residues.map((residue) =>
                        <ResidueList
                            residueSelectionState={structureSelectionState.residues[residue.id]}
                            residue={residue}
                            chainIndex={chainIndex}
                            chainName={chainName}
                            residueId={residue.id}
                            structureIndex={structureIndex}
                            key={structureIndex + structure.type + residue.id + residue.name}
                        />
                    )}
                </List>
            </Collapse>
        </>
    )
})

export default StructureList;
import {ResidueSelectionState} from "../ResidueSelectionState";

export interface ResidueRangeSelector {
    excludeInRange: (first: string, last: string, chainIndex: number, selectionState: ResidueSelectionState) => ResidueSelectionState
    includeInRange: (first: string, last: string, chainIndex: number, selectionState: ResidueSelectionState) => ResidueSelectionState
    excludeAll: (selectionState: ResidueSelectionState) => ResidueSelectionState
}

export default class ResidueRangeSelectorImpl implements ResidueRangeSelector {
    excludeInRange: (first: string, last: string, chainIndex: number, selectionState: ResidueSelectionState) => ResidueSelectionState = (first, last, chainIndex, selectionState) => {
        const structureIndexFirst = this.findResidueInChain(first, chainIndex, selectionState)
        const structureIndexLast = this.findResidueInChain(last, chainIndex, selectionState)
        if (structureIndexFirst > structureIndexLast) {
            // eslint-disable-next-line no-throw-literal
            throw `Invalid range: ${last} < ${first}`
        }
        const partiallyResult: ResidueSelectionState = this.performStateChange(structureIndexFirst, first, structureIndexLast, last, selectionState, chainIndex, true)
        return this.fillMissingResidues(selectionState, partiallyResult)
    }

    includeInRange: (first: string, last: string, chainIndex: number, selectionState: ResidueSelectionState) => ResidueSelectionState = (first, last, chainIndex, selectionState) => {
        const structureIndexFirst = this.findResidueInChain(first, chainIndex, selectionState)
        const structureIndexLast = this.findResidueInChain(last, chainIndex, selectionState)
        if (structureIndexFirst > structureIndexLast) {
            // eslint-disable-next-line no-throw-literal
            throw `Invalid range: ${last} < ${first}`
        }
        const partiallyResult: ResidueSelectionState = this.performStateChange(structureIndexFirst, first, structureIndexLast, last, selectionState, chainIndex, false)
        return this.fillMissingResidues(selectionState, partiallyResult)
    }

    excludeAll: (selectionState: ResidueSelectionState) => ResidueSelectionState = (selectionState) => {
        return this.performStateChangeOnAll(selectionState, true)
    }

    private findResidueInChain: (residueName: string, chainIndex: number, selectionState: ResidueSelectionState) => number = (residueName, chainIndex, selectionState) => {
        const result: [structureIndex: number, residueName: string] = Object.entries(selectionState.value[chainIndex].structures).reduce((acc, curr) => {
            const structure = selectionState.value[chainIndex].structures[parseInt(curr[0], 10)]
            const foundResidue = Object.keys(structure.residues).find((residueNameInner) => residueNameInner === residueName)
            if (foundResidue !== undefined) {
                return [parseInt(curr[0], 10), foundResidue]
            } else {
                return acc
            }
        }, [-1, ""])
        if (result[0] === -1) {
            // eslint-disable-next-line no-throw-literal
            throw `Invalid residue: ${residueName}`
        }
        return result[0]
    }

    private performStateChange: (firstStructure: number, firstResidue: string, lastStructure: number, lastResidue: string, selectionState: ResidueSelectionState, chainIndex: number, state: boolean) => ResidueSelectionState = (firstStructure, firstResidue, lastStructure, lastResidue, selectionState, chainIndex, state) => {
        const result: ResidueSelectionState = JSON.parse(JSON.stringify(selectionState));
        for (let i = firstStructure; i <= lastStructure; i++) {
            let residues = Object.keys(selectionState.value[chainIndex].structures[i].residues)
            if (i === firstStructure) {
                residues = residues.slice(residues.indexOf(firstResidue), residues.length)
            }
            if (i === lastStructure) {
                if (firstStructure === lastStructure)
                    residues = residues.slice(residues.indexOf(firstResidue), residues.indexOf(lastResidue) + 1)
                else
                    residues = residues.slice(0, residues.indexOf(lastResidue) + 1)
            }
            if (result.value[chainIndex].structures[i] === undefined) {
                result.value[chainIndex].structures[i] = {
                    residues: {},
                    excludedResidues: selectionState.value[chainIndex].structures[i].excludedResidues
                }

            }
            residues.forEach((residue) => {
                const prevState = selectionState.value[chainIndex].structures[i].residues[residue]
                if (prevState !== state) {
                    result.value[chainIndex].structures[i].excludedResidues += state ? 1 : -1
                    result.value[chainIndex].excludedResidues += state ? 1 : -1
                }
                result.value[chainIndex].structures[i].residues[residue] = state
            })
        }
        return result
    }

    private performStateChangeOnAll: (selectionState: ResidueSelectionState, state: boolean) => ResidueSelectionState = (selectionState, state) => {
        const result: ResidueSelectionState = {value: {}, proteinCode: selectionState.proteinCode}
        Object.entries(selectionState.value).forEach((chainEntry) => {
            if (result.value[parseInt(chainEntry[0], 10)] === undefined) {
                result.value[parseInt(chainEntry[0], 10)] = {structures: {}, excludedResidues: 0}
            }
            Object.entries(chainEntry[1].structures).forEach((structureEntry) => {
                if (result.value[parseInt(chainEntry[0], 10)].structures[parseInt(structureEntry[0], 10)] === undefined) {
                    result.value[parseInt(chainEntry[0], 10)].structures[parseInt(structureEntry[0], 10)] = {
                        residues: {},
                        excludedResidues: 0
                    }
                }
                Object.entries(structureEntry[1].residues).forEach((residueEntry) => {
                    if (result.value[parseInt(chainEntry[0], 10)].structures[parseInt(structureEntry[0], 10)].residues[residueEntry[0]] === undefined) {
                        result.value[parseInt(chainEntry[0], 10)].structures[parseInt(structureEntry[0], 10)].residues[residueEntry[0]] = state
                        result.value[parseInt(chainEntry[0], 10)].excludedResidues += state ? 1 : 0
                        result.value[parseInt(chainEntry[0], 10)].structures[parseInt(structureEntry[0], 10)].excludedResidues += state ? 1 : 0
                    }

                })
            })
        })
        return result
    }

    private fillMissingResidues: (selectionState: ResidueSelectionState, currentResult: ResidueSelectionState) => ResidueSelectionState = (selectionState, currentResult) => {
        Object.entries(selectionState.value).forEach((chainEntry) => {
            if (currentResult.value[parseInt(chainEntry[0], 10)] === undefined) {
                currentResult.value[parseInt(chainEntry[0], 10)] = {structures: {}, excludedResidues: 0}
            }
            Object.entries(chainEntry[1].structures).forEach((structureEntry) => {
                if (currentResult.value[parseInt(chainEntry[0], 10)].structures[parseInt(structureEntry[0], 10)] === undefined) {
                    currentResult.value[parseInt(chainEntry[0], 10)].structures[parseInt(structureEntry[0], 10)] = {
                        residues: {},
                        excludedResidues: 0
                    }
                }
                Object.entries(structureEntry[1].residues).forEach((residueEntry) => {
                    if (currentResult.value[parseInt(chainEntry[0], 10)].structures[parseInt(structureEntry[0], 10)].residues[residueEntry[0]] === undefined) {
                        const newState = selectionState.value[parseInt(chainEntry[0], 10)].structures[parseInt(structureEntry[0], 10)].residues[residueEntry[0]]
                        currentResult.value[parseInt(chainEntry[0], 10)].structures[parseInt(structureEntry[0], 10)].residues[residueEntry[0]] = newState
                        currentResult.value[parseInt(chainEntry[0], 10)].structures[parseInt(structureEntry[0], 10)].excludedResidues += newState ? 1 : 0
                        currentResult.value[parseInt(chainEntry[0], 10)].excludedResidues += newState ? 1 : 0
                    }

                })
            })
        })
        return currentResult
    }
}
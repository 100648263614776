import Residue from "../proteinModels/Residue";
import LineInterpreter from "./LineInterpreter";
import {ProteinFileRecord} from "./ProteinFileRecord";
import Structure from "../proteinModels/Structure";

export default class ProteinBuilder {

    residueIndexes: { [chainName: string]: { [uuid: string]: number } } = {}

    public createResidues: (lines: string[]) => { [chainName: string]: { [number: string]: Residue } } = (lines) => {
        let residueIterator: { [chainName: string]: number } = {}
        const residues: { [chainName: string]: { [number: string]: Residue } } = {}
        lines.forEach((line) => {
            const recordType = LineInterpreter.getProteinFileRecord(line)
            switch (recordType) {
                case ProteinFileRecord.ATOM:
                case ProteinFileRecord.HETATM:
                case ProteinFileRecord.TER:
                case ProteinFileRecord.ANISOU:
                    if (residues[line[21]] === undefined) {
                        residues[line[21]] = {}
                        this.residueIndexes[line[21]] = {}
                        residueIterator[line[21]] = 0
                    }
                    if (residues[line[21]][LineInterpreter.getResidueSequenceNumberFromAtom(line)] === undefined) {
                        residues[line[21]][LineInterpreter.getResidueSequenceNumberFromAtom(line)] = new Residue(line.slice(17, 20).trim(), line.slice(22, 26).trim(), [])
                        this.residueIndexes[line[21]][LineInterpreter.getResidueSequenceNumberFromAtom(line)] = residueIterator[line[21]]++;
                    }
                    residues[line[21]][LineInterpreter.getResidueSequenceNumberFromAtom(line)].atoms.push(parseInt(line.slice(6, 12).trim()))
            }
        })
        return residues;
    }

    public createStructures: (lines: string[], residues: { [chainName: string]: { [number: string]: Residue } }) => {
        [chainName: string]: Structure[]
    } = (lines, residues) => {
        const structures: { [chainName: string]: { [number: string]: Structure } } = {}
        lines.forEach((line) => {
            const chainIdentifier = LineInterpreter.getChainIdentifier(line)
            const type = LineInterpreter.getProteinFileRecord(line)
            switch (type) {
                case ProteinFileRecord.HELIX:
                case ProteinFileRecord.SHEET:
                    if (structures[chainIdentifier!] === undefined) structures[chainIdentifier!] = {}
                    structures[chainIdentifier!][line.slice(7, 15).trim()] =
                        LineInterpreter.structureFromLine(
                            line,
                            Object.values(residues[chainIdentifier!]),
                            this.residueIndexes
                        )
            }
        })
        return Object.entries(structures).reduce((accumulator: { [chainName: string]: Structure[] }, currentValue) => {
            accumulator[currentValue[0]] = Object.values(currentValue[1]);
            return accumulator
        }, {})
    }


}
import LineInterpreter from "./LineInterpreter";
import {ProteinFileRecord} from "./ProteinFileRecord";
import Structure from "../proteinModels/Structure";
import Residue from "../proteinModels/Residue";
import {StructureType} from "../proteinModels/StructureType";

export default class OtherResiduesFinder {
    public getOtherStructures: (lines: string[], structures: { [chainName: string]: Structure[] }, residues: {
        [chainName: string]: { [number: string]: Residue }
    }) => { [chainName: string]: Structure[] } = (lines, structures, residues) => {
        const residuesBelonging = this.findResiduesNotBelongingToStructures(lines)
        return this.createOtherResidues(residuesBelonging, structures, residues)
    }

    private setBelongingResidues: (lines: string[], resultIndexes: {
        [chainName: string]: { [residueName: string]: number }
    }, resultArray: { [chainName: string]: [string, boolean][] }) => {
        [chainName: string]: [string, boolean][]
    } = (lines, resultIndexes, resultArray) => {
        lines.forEach((line) => {
            switch (LineInterpreter.getProteinFileRecord(line)) {
                case ProteinFileRecord.HELIX:
                case ProteinFileRecord.SHEET:
                    const range: [string, string] = LineInterpreter.residuesRange(line)
                    const [firstIndex, lastIndex] = [
                        resultIndexes[LineInterpreter.getChainIdentifier(line)!][range[0]],
                        resultIndexes[LineInterpreter.getChainIdentifier(line)!][range[1]]
                    ]
                    for (let i = firstIndex; i <= lastIndex; i++) {
                        resultArray[LineInterpreter.getChainIdentifier(line)!][i][1] = true
                    }
                    break
            }
        })
        return resultArray
    }

    private getResultArray: (result: { [chainName: string]: { [residueName: string]: boolean } }) => {
        [chainName: string]: [string, boolean][]
    } = (result) => {
        return Object
            .entries(result)
            .map((entry) => [entry[0], Object.entries(entry[1])])
            .reduce((acc: { [chainName: string]: [string, boolean][] }, curr) => {
                // @ts-ignore
                acc[curr[0]] = curr[1];
                return acc
            }, {})
    }

    private preprocessResidues: (lines: string[]) => [{ [chainName: string]: { [residueName: string]: boolean } }, {
        [chainName: string]: { [residueName: string]: number }
    }] = (lines) => {
        const result: { [chainName: string]: { [residueName: string]: boolean } } = {}
        const resultIndexes: { [chainName: string]: { [residueName: string]: number } } = {}
        let residueIterator: { [chainName: string]: number } = {}
        lines.forEach((line) => {
            switch (LineInterpreter.getProteinFileRecord(line)) {
                case ProteinFileRecord.ATOM:
                case ProteinFileRecord.HETATM:
                case ProteinFileRecord.TER:
                    if (result[LineInterpreter.getChainIdentifier(line)!] === undefined) {
                        result[LineInterpreter.getChainIdentifier(line)!] = {}
                        resultIndexes[LineInterpreter.getChainIdentifier(line)!] = {}
                        residueIterator[LineInterpreter.getChainIdentifier(line)!] = 0
                    }
                    if (resultIndexes[LineInterpreter.getChainIdentifier(line)!][LineInterpreter.getResidueSequenceNumberFromAtom(line)] === undefined) {
                        resultIndexes[LineInterpreter.getChainIdentifier(line)!][LineInterpreter.getResidueSequenceNumberFromAtom(line)] = residueIterator[LineInterpreter.getChainIdentifier(line)!]++;
                    }
                    result[LineInterpreter.getChainIdentifier(line)!][LineInterpreter.getResidueSequenceNumberFromAtom(line)] = false
            }
        })
        return [result, resultIndexes]
    }

    private createOtherResidues: (residuesBelonging: {
        [chainName: string]: { [residueName: string]: boolean }
    }, structures: { [chainName: string]: Structure[] }, residues: {
        [chainName: string]: { [number: string]: Residue }
    }) => { [chainName: string]: Structure[] } = (residuesBelonging, structures, residues) => {
        const result: { [chainName: string]: Structure[] } = {}
        Object.entries(residues).forEach(([chainName, chainResidues]) => {
            let status: "reading" | "skipping" = "skipping"
            result[chainName] = Object.entries(chainResidues).reduce((acc: Structure[], curr, index, array) => {
                if (residuesBelonging[chainName] === undefined && status === "skipping") {
                    status = "reading"
                    acc.push(new Structure(StructureType.OTHER, [curr[1]], curr[0], curr[0]))
                } else if (residuesBelonging[chainName] === undefined && status === "reading") {
                    acc[acc.length - 1].residues.push(curr[1])
                    acc[acc.length - 1].lastResidue = curr[0]
                } else if (!residuesBelonging[chainName][curr[0]] && status === "reading") {
                    acc[acc.length - 1].residues.push(curr[1])
                    acc[acc.length - 1].lastResidue = curr[0]
                } else if (residuesBelonging[chainName][curr[0]] && status === "reading") {
                    status = "skipping"
                } else if (!residuesBelonging[chainName][curr[0]] && status === "skipping") {
                    status = "reading"
                    acc.push(new Structure(StructureType.OTHER, [curr[1]], curr[0], curr[0]))
                }
                return acc
            }, [])
        })
        return result
    }

    private findResiduesNotBelongingToStructures: (lines: string[]) => {
        [chainName: string]: { [residueName: string]: boolean }
    } = (lines) => {

        const preprocessResiduesResult = this.preprocessResidues(lines)
        const resultArray = this.setBelongingResidues(
            lines, preprocessResiduesResult[1], this.getResultArray(preprocessResiduesResult[0])
        )

        return Object.entries(resultArray).reduce(
            (acc: { [chainName: string]: {} }, curr) => {
                acc[curr[0]] = curr[1].reduce(
                    (innerAcc: { [residueName: string]: {} }, innerCurr) => {
                        innerAcc[innerCurr[0]] = innerCurr[1];
                        return innerAcc
                    },
                    {});
                return acc
            },
            {})
    }
}
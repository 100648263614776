import {createBrowserRouter} from "react-router-dom";
import App from "../../../app/App";
import React from "react";
import {dashboardRoute, proteinFragmentRoute, proteinViewRoute} from "./routes";

export default createBrowserRouter([
    {
        path: "/",
        element: <App/>,
        //errorElement: <ErrorPage />,
        children: [
            dashboardRoute,
            proteinViewRoute,
            proteinFragmentRoute
        ],
    },
]);
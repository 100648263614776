import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface ComputationalState {
    value: "not started" | "in progress" | "finished" | "error"
}

const initialState: ComputationalState = {
    value: "not started"
}

export const computationalStateSlice = createSlice({
    name: 'computationalStateSlice',
    initialState: initialState,
    reducers: {
        setComputationalState: (state, action: PayloadAction<ComputationalState>) => {
            state.value = action.payload.value
        },
    },
})


// Action creators are generated for each case reducer function
export const {setComputationalState} = computationalStateSlice.actions

export default computationalStateSlice.reducer
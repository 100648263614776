import {StructureType} from "./StructureType";
import Residue from "./Residue";

export default class Structure {
    set lastResidue(value: string) {
        this._lastResidue = value;
    }
    constructor(type: StructureType, residues: Residue[], firstResidue: string, lastResidue: string) {
        this.type = type
        this.residues = residues
        this._firstResidue = firstResidue
        this._lastResidue = lastResidue
    }

    private _firstResidue: string
    type: StructureType
    residues: Residue[]

    get firstResidue(): string {
        return this._firstResidue;
    }

    private _lastResidue: string

    get lastResidue(): string {
        return this._lastResidue;
    }

    public static fromObject(object: any): Structure {
        return new Structure(
            StructureType[object.type.toUpperCase() as keyof typeof StructureType],
            object.residues.map((residue: any) => Residue.fromObject(residue)),
            object._firstResidue,
            object._lastResidue
        )
    }
}
import {AppBar, Box, Container, IconButton, Menu, Toolbar, Typography} from "@mui/material";
import BiotechIcon from "@mui/icons-material/Biotech";
import React from "react";
import {
    computeRoute,
    csvDownloadRoute,
    dashboardRoute,
    proteinFragmentRoute,
    proteinViewRoute,
    Route,
    txtDownloadRoute
} from "../../../misc/config/router/routes";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import deserializeSearchParams from "../../../misc/config/router/deserializeSearchParams";
import {ProteinSearchParams} from "../../domain/ProteinSearchParams";
import ComputationPerformer from "../../domain/performComputation/ComputationPerformer";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../misc/redux/store";
import {ResidueSelectionState} from "../../domain/ResidueSelectionState";
import Protein from "../../domain/proteinModels/Protein";
import PDBCode from "../../domain/PDBCode";
import {setComputationalState} from "../../../misc/redux/slices/ComputationalStateSlice";
import AppBarButtons from "./AppBarButtons";
import {setResult} from "../../../misc/redux/slices/ComputationalResultSlice";
import DownloadFileRepository from "../../data/repository/DownloadFileRepository";

export type Page = {
    name: string,
    route?: Route,
    state: "idle" | "loading",
    additionalAction?: (page: Page) => void
}

export default function AppBarWrapper() {
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const params = deserializeSearchParams<ProteinSearchParams>(proteinViewRoute.searchParams!, searchParams)
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const navigate = useNavigate()
    const proteinJSON = useSelector((state: RootState) => state.protein.value)
    const residueSelection: ResidueSelectionState = useSelector((state: RootState) => state.residueSelection)
    const pdbCode = useSelector((state: RootState) => state.pdbCode.value)
    const dispatch = useDispatch()
    const computationalState: "not started" | "in progress" | "finished" | "error" = useSelector((state: RootState) => state.computationalState.value)

    const pages: Page[] = [
        {
            name: 'Details',
            route: proteinViewRoute,
            state: "idle",
            additionalAction: (page) => navigate(`${page.route!.path}/?${new URLSearchParams(params).toString()}`, {replace: true})
        },
        {
            name: 'Fragment',
            route: proteinFragmentRoute,
            state: "idle",
            additionalAction: (page) => navigate(`${page.route!.path}/?${new URLSearchParams(params).toString()}`, {replace: true})
        },
        {
            name: 'Compute',
            route: computeRoute,
            state: computationalState === "in progress" ? "loading" : "idle",
            additionalAction: (page) => {
                if (proteinJSON !== undefined && pdbCode !== undefined) {

                    new ComputationPerformer(handleComputationalStateChange).performComputation(residueSelection, Protein.fromObject(JSON.parse(proteinJSON)), new PDBCode(pdbCode))
                        .then((result) => {
                            const filteredResult = result.split("\n").filter((_, index) => index === 1 || index === 5).join("\n")
                            dispatch(setResult({value: `Computation success! Now you can download .txt and .csv files in top menu.\n ${filteredResult}`}))
                        })
                        .catch((e: any) => dispatch(setResult({value: "An error during computation occurred. For more details, please contact with administrator."})))
                }
            }
        }
    ]

    if (computationalState === "finished" && pdbCode !== undefined) {
        pages.push(
            {
                name: "TXT",
                route: txtDownloadRoute,
                state: "idle",
                additionalAction: (page) => new DownloadFileRepository().download(txtDownloadRoute.path, `${pdbCode}.txt`, 'text/plain')
            },
            {
                name: "CSV",
                route: csvDownloadRoute,
                state: "idle",
                additionalAction: (page) => new DownloadFileRepository().download(csvDownloadRoute.path, `${pdbCode}.csv`, 'text/csv')
            }
        )
    }

    const handleComputationalStateChange = (state: "not started" | "in progress" | "finished" | "error") => {
        dispatch(setComputationalState({value: state}))
    }

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleMenuButtonClick = (page: Page) => {
        if (page.additionalAction !== undefined) {
            page.additionalAction(page)
        }
        handleCloseNavMenu()
    }

    return (
        location.pathname !== `${dashboardRoute.path}` ?
            <AppBar position="sticky" variant="elevation" style={{zIndex: 9999}}>
                <Container maxWidth="xl" sx={{margin: 0}}>
                    <Toolbar variant="dense" disableGutters>
                        <Box sx={{display: {xs: 'flex', md: 'none'}, justifyContent: "left"}}>
                            <IconButton
                                size="large"
                                data-testid="menu-button"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon/>
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}

                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: {xs: 'block', md: 'none'},
                                    zIndex: 9999
                                }}
                            >
                                <AppBarButtons pages={pages} handleMenuButtonClick={handleMenuButtonClick}/>
                            </Menu>
                        </Box>
                        <BiotechIcon sx={{mr: 1}}/>
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href="#app-bar-with-responsive-menu"
                            sx={{
                                mr: 2,
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        > {params.id}
                        </Typography>
                        <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                            <AppBarButtons pages={pages} handleMenuButtonClick={handleMenuButtonClick}/>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar> : null
    )
}

import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import Protein from "../../../app/domain/proteinModels/Protein";
import {ResidueSelectionState} from "../../../app/domain/ResidueSelectionState";


const initialState: ResidueSelectionState = {
    value: {}, proteinCode: ""
}

export const residueSelectionSlice = createSlice({
    name: 'residueSelectionSlice',
    initialState: initialState,
    reducers: {
        setResidueState: (state, action: PayloadAction<{
            chainIndex: number,
            structureIndex: number,
            residueId: string,
            newState: boolean
        }>) => {
            state.value[action.payload.chainIndex].structures[action.payload.structureIndex].residues[action.payload.residueId] = action.payload.newState
            state.value[action.payload.chainIndex].excludedResidues += action.payload.newState ? 1 : -1
            state.value[action.payload.chainIndex].structures[action.payload.structureIndex].excludedResidues += action.payload.newState ? 1 : -1
        },
        updateState: (state, action: PayloadAction<ResidueSelectionState>) => {
            state.value = action.payload.value
        },
        initializeState: (state, action: PayloadAction<[Protein, string]>) => {
            const result: {
                [chainIndex: number]: {
                    structures: {
                        [structureIndex: number]: {
                            residues: { [residueName: string]: boolean },
                            excludedResidues: number
                        },
                    },
                    excludedResidues: number
                }
            } = {}
            action.payload[0].chains.forEach((chain, chainIndex) => {
                chain.structures.forEach((structure, structureIndex) => {
                    structure.residues.forEach((residue) => {
                        if (result[chainIndex] === undefined) {
                            result[chainIndex] = {structures: {}, excludedResidues: 0}
                        }
                        if (result[chainIndex].structures[structureIndex] === undefined) {
                            result[chainIndex].structures[structureIndex] = {excludedResidues: 0, residues: {}}
                        }
                        if (result[chainIndex].structures[structureIndex].residues[residue.id] === undefined) {
                            result[chainIndex].structures[structureIndex].residues[residue.id] = false
                        }
                    })
                })
            })
            state.value = result
            state.proteinCode = action.payload[1]
        },
        setStructureState: (state, action: PayloadAction<{
            chainIndex: number,
            structureIndex: number,
            newState: boolean
        }>) => {
            Object.entries(state.value[action.payload.chainIndex].structures[action.payload.structureIndex].residues).forEach((entry) => {
                state.value[action.payload.chainIndex].structures[action.payload.structureIndex].residues[entry[0]] = action.payload.newState
            })
            const residuesNumber = Object.entries(state.value[action.payload.chainIndex].structures[action.payload.structureIndex].residues).length
            state.value[action.payload.chainIndex].structures[action.payload.structureIndex].excludedResidues = action.payload.newState ?
                residuesNumber :
                0
            state.value[action.payload.chainIndex].excludedResidues += action.payload.newState ? residuesNumber : -residuesNumber
        },
        setChainState: (state, action: PayloadAction<{ chainIndex: number, newState: boolean }>) => {
            let totalResiduesNumber = 0
            Object.entries(state.value[action.payload.chainIndex].structures).forEach(([structureIndex, structure]) => {
                Object.entries(state.value[action.payload.chainIndex].structures[parseInt(structureIndex, 10)].residues).forEach(([residueName, residueState]) => {
                    state.value[action.payload.chainIndex].structures[parseInt(structureIndex, 10)].residues[residueName] = action.payload.newState
                })
                const residuesNumber = Object.entries(state.value[action.payload.chainIndex].structures[parseInt(structureIndex, 10)].residues).length
                state.value[action.payload.chainIndex].structures[parseInt(structureIndex, 10)].excludedResidues = action.payload.newState ? residuesNumber : 0
                totalResiduesNumber += residuesNumber
            })
            state.value[action.payload.chainIndex].excludedResidues = action.payload.newState ? totalResiduesNumber : 0
        }
    },
})

// Action creators are generated for each case reducer function
export const {
    setResidueState,
    initializeState,
    setStructureState,
    setChainState,
    updateState
} = residueSelectionSlice.actions

export default residueSelectionSlice.reducer
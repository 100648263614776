import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface PDBCodeState {
    value?: string
}

const initialState: PDBCodeState = {
    value: undefined
}

export const pdbCodeSlice = createSlice({
    name: 'pdbCode',
    initialState: initialState,
    reducers: {
        setPdbCode: (state, action: PayloadAction<PDBCodeState>) => {
            state.value = action.payload.value
        },
    },
})

// Action creators are generated for each case reducer function
export const {setPdbCode} = pdbCodeSlice.actions

export default pdbCodeSlice.reducer
export default class PDBCode {
    code: string
    errors: string[] = []

    constructor(code: string) {
        this.code = code
    }

    valid(): boolean {
        if (this.code.toLowerCase().length !== 4) {
            this.errors.push("The code's length must be equal to 4")
            return false
        }

        if (!/^[a-z0-9]+$/g.test(this.code.toLowerCase())) {
            this.errors.push("The code must consist of letters and numbers")
            return false
        }
        return true
    }
}
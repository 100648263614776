import {CircularProgress, MenuItem, Typography} from "@mui/material";
import React from "react";
import {Page} from "./AppBarWrapper";

type AppBarButtonsProps = {
    pages: Page[],
    handleMenuButtonClick: (page: Page) => void
}

export default function AppBarButtons({pages, handleMenuButtonClick}: AppBarButtonsProps) {
    return (
        pages.map((page) => (
            <MenuItem key={page.name} disabled={page.state === "loading"} onClick={() => handleMenuButtonClick(page)}>
                {page.route?.routeElement !== undefined ? page.route!.routeElement :
                    <Typography textAlign="center">{page.name}</Typography>}
                {page.state === "loading" ?
                    <CircularProgress color={"inherit"} size={"1.5rem"} sx={{marginLeft: 1}}/> : null}
            </MenuItem>
        ))
    )
}
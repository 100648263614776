import {ResidueSelectionState} from "../ResidueSelectionState";
import Protein from "../proteinModels/Protein";
import ResidueSelectionParser from "../proteinResidueSelection/ResidueSelectionParser";
import ComputationalRepositoryImpl from "../../data/repository/ComputationalRepository";
import PDBCode from "../PDBCode";

export default class ComputationPerformer {

    changeComputationalState: (state: "not started" | "in progress" | "finished" | "error") => void

    constructor(changeComputationalState: (state: "not started" | "in progress" | "finished" | "error") => void) {
        this.changeComputationalState = changeComputationalState
    }

    performComputation: (selection: ResidueSelectionState, protein: Protein, pdbCode: PDBCode) => Promise<string> = (selection, protein, pdbCode) => {
        const parsedSelection = new ResidueSelectionParser().parseToCompute(selection, protein)
        return new ComputationalRepositoryImpl(this.changeComputationalState).startComputation(parsedSelection, pdbCode)
    }
}
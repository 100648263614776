import {configureStore} from "@reduxjs/toolkit";
import themeSlice from "./slices/ThemeSlice";
import pdbCodeSlice from "./slices/PDBCodeSlices";
import protein from "./slices/ProteinSlices"
import proteinFile from "./slices/ProteinFileSlices"
import residueSelection from "./slices/ResiduesSelectionSlices";
import computationalState from "./slices/ComputationalStateSlice";
import computationalResult from "./slices/ComputationalResultSlice";

const store = configureStore({
    reducer: {
        theme: themeSlice,
        pdbCode: pdbCodeSlice,
        protein: protein,
        proteinFile: proteinFile,
        residueSelection: residueSelection,
        computationalState: computationalState,
        computationalResult: computationalResult
    },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store

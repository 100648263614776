import {ProteinFileRecord} from "./ProteinFileRecord";
import Residue from "../proteinModels/Residue";
import Structure from "../proteinModels/Structure";
import {StructureType} from "../proteinModels/StructureType";

export default class LineInterpreter {
    public static residuesSubarray: (line: string, residuesArray: Residue[], residueIndexes: {
        [chainName: string]: { [uuid: string]: number }
    }) => Residue[] = (line, residuesArray, residueIndexes) => {
        switch (LineInterpreter.getProteinFileRecord(line)) {
            case ProteinFileRecord.HELIX:
                return residuesArray.slice(residueIndexes[line[19]][line.slice(21, 25).trim()], residueIndexes[line[19]][line.slice(33, 37).trim()] + 1)
            case ProteinFileRecord.SHEET:
                return residuesArray.slice(residueIndexes[line[21]][line.slice(22, 26).trim()], residueIndexes[line[21]][line.slice(33, 37).trim()] + 1)
            default:
                throw new Error("wrong protein file record type")
        }
    }

    public static residuesRange: (line: string) => [string, string] = (line) => {
        switch (LineInterpreter.getProteinFileRecord(line)) {
            case ProteinFileRecord.HELIX:
                return [line.slice(21, 25).trim(), line.slice(33, 37).trim()]
            case ProteinFileRecord.SHEET:
                return [line.slice(22, 26).trim(), line.slice(33, 37).trim()]
            default:
                throw new Error("wrong protein file record type")
        }
    }

    public static getProteinFileRecord: (line: string) => ProteinFileRecord = (line) => {
        let result = ProteinFileRecord[line.slice(0, 6).trim() as keyof typeof ProteinFileRecord]
        if (result === undefined) result = ProteinFileRecord.OTHER
        return result
    }

    public static getChainIdentifier: (line: string) =>
        string | undefined = (line) => {
        switch (LineInterpreter.getProteinFileRecord(line)) {
            case ProteinFileRecord.SHEET:
            case ProteinFileRecord.ATOM:
            case ProteinFileRecord.HETATM:
            case ProteinFileRecord.ANISOU:
            case ProteinFileRecord.TER:
                return line[21]
            case ProteinFileRecord.HELIX:
                return line[19]
        }
    }

    public static toLines: (text: string) => string[] = (text) => {
        return text.split("\n")
    }

    public static getResidueSequenceNumberFromAtom: (line: string) => string = (line) => {
        switch (LineInterpreter.getProteinFileRecord(line)) {
            case ProteinFileRecord.HETATM:
            case ProteinFileRecord.ATOM:
            case ProteinFileRecord.TER:
            case ProteinFileRecord.ANISOU:
                return line.slice(22, 26).trim()
            default:
                throw new Error("wrong protein file record type")
        }
    }

    public static structureFromLine: (line: string, residuesArray: Residue[], residueIndexes: {
        [chainName: string]: { [uuid: string]: number }
    }) => Structure = (line, residuesArray, residueIndexes) => {
        const type = LineInterpreter.getProteinFileRecord(line)
        switch (type) {
            case ProteinFileRecord.SHEET:
                return new Structure(StructureType.SHEET, LineInterpreter.residuesSubarray(line, residuesArray, residueIndexes), ...LineInterpreter.residuesRange(line,))
            case ProteinFileRecord.HELIX:
                return new Structure(StructureType.HELIX, LineInterpreter.residuesSubarray(line, residuesArray, residueIndexes), ...LineInterpreter.residuesRange(line))
            default:
                throw new Error("wrong protein file record type")
        }
    }
}